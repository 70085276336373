import InPageLoader from '~/src/components/loader/InPageLoader'
import { useEoValue } from '~/src/hooks/useEoState'
import { Customer } from '@eo-storefronts/eo-core'
import { IS_LOGGED_IN_SELECTOR } from '~/src/stores/authentication'
import { CUSTOMER_STATE } from '~/src/stores/customer'
import AuthRequired from '~/src/components/auth/AuthRequired'
import useGetProfileTemplate from '~/src/components/profile/profile-layout/styles/useGetProfileTemplate'
import TabsLayout from '~/src/components/utils/layout/Tabs/TabsLayout'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { useTranslations } from '~/src/hooks/useTranslations'
import { Box } from '@mui/material'
import useGetProfileNavigationItems from '~/src/hooks/router/navigation/useGetProfileNavigationItems'
import { Outlet, useLocation } from 'react-router-dom'
import { Suspense } from 'react'
import SlideFromBottom from '~/src/components/mui-wrappers/animations/motion/SlideFromBottom'
import SlideFromRight from '~/src/components/mui-wrappers/animations/motion/SlideFromRight'
import useGetHeaderOffset from '~/src/hooks/layout/useGetHeaderOffset'

interface Props {
  isLoading?: boolean,
}

const ProfileLayout = ({ isLoading }: Props) => {
  const { pathname } = useLocation()
  const isLoggedIn = useEoValue<boolean>(IS_LOGGED_IN_SELECTOR)
  const customer = useEoValue<Customer | null>(CUSTOMER_STATE)
  const style = useGetProfileTemplate()
  const { t } = useTranslations()
  const navItems = useGetProfileNavigationItems()
  const { getOffset } = useGetHeaderOffset()

  const _getCurrentPath = (path: string): string | null => {
    if (path.includes(RoutesEnum.ACCOUNT)) {
      return 'account'
    }

    if (path.includes(RoutesEnum.ADDRESSES) || path.includes(RoutesEnum.ADDRESS)) {
      return 'addresses'
    }

    if (path.includes(RoutesEnum.MARKETING)) {
      return 'marketing'
    }

    if (path.includes(RoutesEnum.LOYALTY)) {
      return 'loyalty'
    }

    return null
  }

  if (!isLoading && !customer && !isLoggedIn) {
    return <AuthRequired/>
  }

  return (
    <SlideFromRight
      pathname={'PROFILE'}
      transition={{
        duration: .5
      }}
    >
      <Box
        sx={style.getPageSx(getOffset())}
        id='profile_header__container'>
        <TabsLayout
          title={t('profile.accountDetails')}
          TabsProps={{
            navItems,
            onTabChange: _getCurrentPath,
            id: 'profile_nav'
          }}
        >
          {isLoading && <InPageLoader/>}
          {!isLoading && (
            <SlideFromBottom
              pathname={pathname}
              y={10}>
              <Suspense>
                <Outlet/>
              </Suspense>
            </SlideFromBottom>
          )}
        </TabsLayout>
      </Box>
    </SlideFromRight>
  )
}

export default ProfileLayout
